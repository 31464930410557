import React from 'react';
import './PhotoViewer.css';

const PhotoViewer = props => {
  return (
    <div hidden={!props.isOpen}>
    <img className="photo-viewer-image" src={props.imageSrc} alt="beautiful" onClick={e => props.onClickImage(e)}></img>
    </div>
  ) 
}

export default PhotoViewer;