// import logo from './logo.svg';
import React from 'react';
import { useState } from "react";
import './App.css';
import './PhotoViewer';
import PhotoViewer from './PhotoViewer';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h1>
          Jing Photo Studio
        </h1>
      <NavBar/>
      </header>
      <PhotoBoard />
    </div>
  );
}

const NavBar = () => {
  return (
    <div className="nav-bar">
      <a
      className="App-link"
      href="https://jingphotostudio.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Home
    </a>
    <a href="mailto:jingphotostudio@gmail.com">Contact</a>
  </div>
  );
}

const PhotoBoard = props => {
  return (
    <div className="photo-board">
      <PhotoCollage photoTheme="flowers" />
      <PhotoCollage photoTheme="birds" />
      <PhotoCollage photoTheme="insects" />
      <PhotoCollage photoTheme="fishes" />
    </div>
  );
}

const PhotoCollage = props => {
  const n = 3; // number of rows
  return (
    <div className="photo-collage">
      <h2 className="photo-collage-title">{props.photoTheme}</h2>
      {[...Array(n)].map((e, rowId) => 
        <PhotoRow photoTheme={props.photoTheme} rowId={rowId} />
      )}
    </div>
  );
}

const PhotoRow = props => {
  const n = 3; // number of columns
  return (
    <div className="photo-row">
      {[...Array(n)].map((e, colId) => {
        const imageId = props.rowId*3 + colId;
        const imageName = `000${imageId + 1}`;
        return <PhotoCell imageSrc={`resource/images/${props.photoTheme}/${imageName}.jpg`} />
      })}
    </div>
  );
}

const PhotoCell = props => {
  const [isPhotoPreviewerOpen, setIsPhotoPreviewerOpen] = useState(false);
  const onClickImage = e => {
    console.log(e);
    setIsPhotoPreviewerOpen(isOpen => !isOpen);
  }
  const imageOriginalSrc = props.imageSrc.replace('.jpg', '_original.jpg');
  return (
    <>
      <img className="photo-cell" src={props.imageSrc} alt={`Beautiful ${props.imageSrc}`} onClick={onClickImage}></img>
      <PhotoViewer imageSrc={imageOriginalSrc} isOpen={isPhotoPreviewerOpen} onClickImage={onClickImage}/>
    </>
  );
}
export default App;
